export enum CLIENT_EVENTS {
    CONNECTED = "from_client:connected",
    ICE_CANDIDATE = "from_client:ice_candidate",
    PEER_ANSWER = "from_client:peer_answer",
    DISCONNECT = "from_client:disconnect",
}

export enum SERVER_EVENTS {
    KILL = "from_server:kill",
    PEER_OFFER = "from_server:peer_offer",
    PEER_CANDIDATE = "from_server:peer_candidate",
    ROOM_NOT_FOUND = "from_server:room_not_found",
    ICE_CANDIDATE = "from_server:ice_candidate",
    ANSWER = "from_server:answer",
    CONNECT_CLIENT = "from_server:connect_client",
    DISCONNECT_CLIENT = "from_server:disconnect_client",
}

export enum BROWSER_EVENTS {

}