import useSWRMutation from "swr/mutation";
import { Button } from "../../ui/Button";
import useSWR from "swr";
import { useState } from "react";

const fetcher = (url: string) => fetch(url).then((r) => r.json());

async function sendRequest(url: string, { arg }: { arg?: {} }) {
  return fetch(url, {
    method: "GET",
    body: JSON.stringify(arg),
  }).then((res) => res.json());
}

export const SA = () => {
  const [stopStreamId, setStopStreamId] = useState("");

  const { data, mutate } = useSWR("/api/v1/bro/all", fetcher);
  const { trigger: triggerStart, isMutating: startIsMutating } = useSWRMutation(
    "/api/v1/bro/start",
    sendRequest,
  );

  const { trigger: triggerStop, isMutating: stopIsMutating } = useSWRMutation(
    `/api/v1/bro/stop?id=${stopStreamId}`,
    sendRequest,
  );

  return (
    <div className="h-dvh w-full text-center content-center">
      <h1 className="text-2xl">All streaming list:</h1>
      {data?.data?.length ? (
        <ul className="list-inside">
          {data.data.map((item: string) => (
            <li key={item} className="list-disc mt-10">
              <a
                href={`/?stream=${encodeURIComponent(item)}`}
                target="_blank"
                rel="noreferrer"
                className="text-indigo-700"
              >
                {item}
              </a>
              <Button
                className="ml-20"
                disabled={stopIsMutating}
                variant="destructive"
                onClick={() => {
                  setStopStreamId(item);
                  setTimeout(async () => {
                    const result = await triggerStop();
                    mutate({
                      data: [
                        ...(data?.data || []).filter(
                          (e: string) => e !== result.data,
                        ),
                      ],
                    });
                  });
                }}
              >
                Stop
              </Button>
            </li>
          ))}
        </ul>
      ) : null}

      <Button
        className="mt-20"
        disabled={startIsMutating}
        onClick={async () => {
          const result = await triggerStart();
          mutate({ data: [...(data?.data || []), result.data] });
        }}
      >
        Start
      </Button>
    </div>
  );
};
