import React from "react";
// import { WatchNPlay } from "./WatchNPlay1";
import { WatchNPlay } from "./components/WatchNPlay2";
import { Routes, Route } from "react-router-dom";
import { SA } from "./components/SA";

function App() {
  return (
    <Routes>
      <Route index element={<WatchNPlay />} />
      <Route path="sa" element={<SA />} />
    </Routes>
  );
}

export default App;
