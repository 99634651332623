import { io, Socket } from "socket.io-client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { CLIENT_EVENTS, SERVER_EVENTS } from "../../typings";

const fetcher = (url: string) => fetch(url).then((r) => r.json());

export const WatchNPlay = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [needInteract, setNeedInteract] = useState(isLoading);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const socket = useMemo<Socket>(
    () =>
      io("/", {
        path: "/stream/",
        transports: [
          ...(process.env.NODE_ENV === "production" ? ["websocket"] : []),
          "polling",
        ],
        autoConnect: true,
        reconnection: true,
        reconnectionDelay: 1000,
        query: {
          cookie: document.cookie,
        },
      }),
    [],
  );
  const [search] = useSearchParams();
  const ROOM_ID = search.get("room");

  const { data, error, mutate } = useSWR("/api/v1/bro/all", fetcher, {
    revalidateIfStale: false,
  });

  const showConnectionState = useCallback(() => {
    if (!peerConnectionRef.current) {
      return;
    }

    // Append to the body to left top corner info about the connection state of all peers with their ids
    let connectionState = document.getElementById("connectionState");
    if (!connectionState) {
      connectionState = document.createElement("div");
      connectionState.id = "connectionState";
      connectionState.style.position = "fixed";
      connectionState.style.top = "0";
      connectionState.style.left = "0";
      connectionState.style.zIndex = "1000";
      connectionState.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      connectionState.style.color = "white";
      connectionState.style.padding = "5px";
      connectionState.style.fontFamily = "Arial";
      document.body.appendChild(connectionState);
    }
    connectionState.innerText = "";

    connectionState.innerText += `connection - ${peerConnectionRef.current?.connectionState}; ice - ${peerConnectionRef.current?.iceConnectionState}`;
  }, []);

  useEffect(() => {
    if (needInteract || !ROOM_ID) return;

    const configuration: RTCConfiguration = {
      iceServers: [
        // {
        //   urls: "turn:localhost:3478",
        //   username: "username",
        //   credential: "password",
        // },
        { urls: "stun:stun.l.google.com:19302" },
        { urls: "stun:stun1.l.google.com:19302" },
        { urls: "stun:stun2.l.google.com:19302" },
        { urls: "stun:stun3.l.google.com:19302" },
      ],
      // iceCandidatePoolSize: 10,
    };

    const connect = async () => {
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }

      peerConnectionRef.current = new RTCPeerConnection(configuration);
      const peerConnection = peerConnectionRef.current;

      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit(CLIENT_EVENTS.ICE_CANDIDATE, {
            room: ROOM_ID,
            candidate: event.candidate,
          });
        }
      };

      peerConnection.ontrack = (event) => {
        if (videoRef.current && event.streams[0]) {
          videoRef.current.srcObject = event.streams[0];

          videoRef.current.onplay = () => {
            setShowPlayButton(false);
          };
          setIsLoading(false);

          setTimeout(() => {
            setShowPlayButton(true);
            videoRef.current?.play();
          }, 0);
        } else {
          console.error("Video ref or stream is null");
        }
      };

      peerConnection.onconnectionstatechange = showConnectionState;

      peerConnection.oniceconnectionstatechange = showConnectionState;

      socket.emit(CLIENT_EVENTS.CONNECTED, { room: ROOM_ID });
    };

    socket.on(SERVER_EVENTS.PEER_OFFER, async (offer) => {
      if (!peerConnectionRef.current) {
        await connect();
      }

      try {
        await peerConnectionRef.current!.setRemoteDescription(
          new RTCSessionDescription(offer),
        );
        const answer = await peerConnectionRef.current!.createAnswer();
        await peerConnectionRef.current!.setLocalDescription(answer);
        socket.emit(CLIENT_EVENTS.PEER_ANSWER, { room: ROOM_ID, answer });
      } catch (error) {
        console.error("Error handling offer:", error);
      }
    });

    socket.on(SERVER_EVENTS.PEER_CANDIDATE, async (candidate) => {
      if (
        peerConnectionRef.current &&
        peerConnectionRef.current.remoteDescription
      ) {
        try {
          await peerConnectionRef.current.addIceCandidate(
            new RTCIceCandidate(candidate),
          );
        } catch (error) {
          console.error("Error adding ICE candidate:", error);
        }
      }
    });

    socket.on(SERVER_EVENTS.KILL, () => {
      console.log("Received kill signal from server");
      if (
        videoRef.current &&
        videoRef.current.srcObject instanceof MediaStream
      ) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
      alert("Server was stopped");
      mutate({ data: [...data.data.filter((e: string) => e !== ROOM_ID)] });
      navigate("/");
    });

    socket.on(SERVER_EVENTS.ROOM_NOT_FOUND, () => {
      alert("Room not exists");
      socket.disconnect();
      navigate("/");
      mutate({ data: [...data.data.filter((e: string) => e !== ROOM_ID)] });

      // Need to reconnect to the socket with n
      setTimeout(() => {
        socket.connect();
      }, 0);
    });

    connect();

    return () => {
      socket.emit(CLIENT_EVENTS.DISCONNECT, { room: ROOM_ID });
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
      socket.off(SERVER_EVENTS.PEER_OFFER);
      socket.off(SERVER_EVENTS.PEER_CANDIDATE);
      socket.off(SERVER_EVENTS.KILL);
      socket.off(SERVER_EVENTS.ROOM_NOT_FOUND);
    };
  }, [
    ROOM_ID,
    data?.data,
    mutate,
    navigate,
    needInteract,
    showConnectionState,
    socket,
  ]);

  useEffect(() => {
    const handleInteraction = () => {
      setNeedInteract(false);
      document.removeEventListener("click", handleInteraction);
    };

    document.addEventListener("click", handleInteraction);
    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, []);

  if (!ROOM_ID) {
    return (
      <div className="w-full h-lvh text-center content-center">
        {data?.data?.length ? (
          <>
            <h1 className="text-2xl">
              Select one item from list to connect to stream
            </h1>
            <ul className="list-inside">
              {data.data.map((item: string) => (
                <li key={item} className="list-disc">
                  <Link
                    to={`/?room=${encodeURIComponent(item)}`}
                    className="text-indigo-700"
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <h1 className="text-2xl">No available streams</h1>
        )}
      </div>
    );
  }

  return (
    <div className="h-lvh w-full content-center text-center">
      {needInteract && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: "black",
            opacity: 0.96,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ color: "white" }}>
            Do any click on page to interact first
          </div>
        </div>
      )}

      <>
        {isLoading ? (
          <h1 className="text-7xl">Loading...</h1>
        ) : error ? (
          <pre>{error}</pre>
        ) : null}

        <div
          className="flex flex-col items-center"
          style={{ opacity: isLoading ? 0 : 1 }}
        >
          <div style={{ fontWeight: "bold", margin: "15px", fontSize: "20px" }}>
            Live stream
          </div>
          <video width="288px" height="546px" controls={false} ref={videoRef} />
          {showPlayButton && (
            <div>
              <button onClick={() => videoRef.current?.play()}>Play</button>
            </div>
          )}
        </div>
      </>
    </div>
  );
};
